<template>
    <div v-if="order" class="popup full w-100 m-0 p-0">
        <!-- NavBar -->
        <n-navbar />
        <div class="popup-container full bags-editor px-3 py-4">
            <div class="flex-element between center mb-2" style="margin-top: 56px">
                <!-- ShopName -->
                <span class="font-27 text-black font-weight-700 line-height-15">{{ order.merchantName }} {{ order.shop.name }}</span>
                <!-- PhoneButton -->
                <button class="p-0 border-radius-50 bg-lightwhite size-40 line-height-1">
                    <a :href="'tel:' + order.shop.phone" target="_blank">
                        <img src="@/assets/icons/ic-phone.svg" />
                    </a>
                </button>
            </div>
            <!-- CustomerName -->
            <div class="line-height-15 pb-4 mb-4 b-b-1">
                <span class="text-dark-warm-grey font-17">{{ order.customer.name }} 先生/小姐您好，感謝您的訂購</span>
            </div>
            <!-- OrderInfo -->
            <div class="top-wrapper">
                <!-- OrderNum -->
                <div class="flex-element center between mb-3">
                    <div class="flex-element">
                        <img class="mr-2" src="@/assets/icons/ic-order.svg" />
                        <span class="font-17 text-black font-weight-500">現場叫號</span>
                    </div>
                    <span class="font-19 text-black font-weight-700">{{ 'T' + String(order.uniNum).substring(8) }}</span>
                </div>
                <!-- UniNum -->
                <div class="flex-element center between mb-3">
                    <div class="flex-element">
                        <img class="mr-2" src="@/assets/icons/ic-order.svg" />
                        <span class="font-17 text-black font-weight-500">訂單號碼</span>
                    </div>
                    <span class="font-19 text-black font-weight-700">{{ order.uniNum }}</span>
                </div>
                <!-- OrderMethod -->
                <div class="flex-element center between mb-3">
                    <div class="flex-element">
                        <img class="mr-2" src="@/assets/icons/ic-order.svg" />
                        <span class="font-17 text-black font-weight-500">訂單方式</span>
                    </div>
                    <span class="font-19 text-black font-weight-700">{{ order.source == 'BYOD' ? '網上' : '' }}{{ order.method == 'PICKUP' ? '自取' : '外送' }}</span>
                </div>
                <!-- Address -->
                <div v-if="order.method == 'DELIVERY'" class="flex-element center between mb-3">
                    <div class="flex-element">
                        <img class="mr-2" src="@/assets/icons/ic-order.svg" />
                        <span class="font-17 text-black font-weight-500">送餐地址</span>
                    </div>
                    <span class="font-19 text-black font-weight-700" style="max-width: 65%; text-align: right;">{{ order.customer.address }}</span>
                </div>
                <div class="reservation-container m-0">
                    <div class="title">您的訂單</div>
                    <!-- begin::orderStatus -->
                    <div class="bg-white border-radius-8 p-3">
                        <div class="font-19 font-weight-700 line-height-15" :class="computedStatus.class">{{ computedStatus.text }}</div>
                        <div class="font-15 text-dark-warm-grey line-height-15">{{ computedStatus.timeText }}</div>
                    </div>
                    <!-- OrderInfo -->
                    <div class="mt-3 px-2">
                        <div class="flex-element center between mb-3">
                            <span class="text-dark-warm-grey font-weight-500 font-15 line-height-15">預訂時間</span>
                            <span class="text-black font-weight-500 font-17 line-height-15">{{ orderReservationAt }}</span>
                        </div>
                        <div class="flex-element center between mb-3">
                            <span span class="text-dark-warm-grey font-weight-500 font-15 line-height-15">會員姓名</span>
                            <span class="text-black font-weight-500 font-17 line-height-15">{{ order.customer.name }}</span>
                        </div>
                        <div class="flex-element center between">
                            <span class="text-dark-warm-grey font-weight-500 font-15 line-height-15">會員電話</span>
                            <span class="text-black font-weight-500 font-17 line-height-15">{{ order.customer.phone }}</span>
                        </div>
                        <div class="mt-18">
                            <div class="text-dark-warm-grey font-weight-500 font-15 line-height-15">訂單條碼（取餐時掃描）</div>
                            <div class="p-40 pb-12 text-center qrcode">
                                <qrcode :value="$route.params.id" :options="{ width: 295 }" tag="img"></qrcode>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CartContainer -->
                <div class="cart-container">
                    <div class="order-header b-b-1">
                        <div class="font-17 text-dark-warm-grey font-weight-500 pb-3 line-height-15">餐點內容</div>
                    </div>
                    <div class="cart-list" v-for="(product, index) in order.products" :key="index">
                        <div class="cart-item" style="align-items: flex-start">
                            <span class="bg-white font-17 text-blue font-weight-700 text-center border-radius-16 mr-12 py-2 line-height-1" style="min-width: 48px">{{ product.count }}</span>
                            <div class="main">
                                <div class="sub">
                                    <div class="product-name">{{ product.name }}</div>
                                    <div class="price font-17">${{ product.total }}</div>
                                </div>
                                <div class="sub" v-for="(item, index) in product.items" :key="index">
                                    <div class="item">
                                        {{ item.name }}
                                        ${{ item.price[order.method] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- DiscountList -->
                    <template v-if="order.discounts.length > 0">
                        <div  v-for="(discount, index) in order.discounts" :key="index" class="cart-list">
                            <div class="cart-item" style="align-items: flex-start">
                                <span class="bg-white font-17 text-blue font-weight-700 text-center border-radius-16 mr-12 py-2 line-height-1" style="min-width: 48px">1</span>
                                <!-- ProductInfo -->
                                <div class="main">
                                    <div class="sub">
                                        <div class="product-name">{{ discount.name }}</div>
                                        <div class="price font-17">${{ discount.total }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="total-container">
                        <div class="sub pl-84 pb-3">
                            <div>商品小計</div>
                            <div class="black">${{ order.productTotal }}</div>
                        </div>
                        <div v-if="order.discountTotal > 0" class="sub pl-84 pb-3">
                            <div>優惠小計</div>
                            <div class="black">${{ order.discountTotal }}</div>
                        </div>
                        <div class="sub pl-84 pb-3">
                            <div>獲得積分</div>
                            <div class="black">{{ order.grandTotal }}分</div>
                        </div>
                        <div class="sub b-t-1">
                            <div class="font-weight-500 emphasize mt-2">總計</div>
                            <div class="black-bold mt-2">${{ order.grandTotal }}</div>
                        </div>
                    </div>
                </div>
                <!-- RemarkContainer -->
                <div class="border-container note mb-3">
                    <div class="note-container py-4">
                        <div class="name">訂單備註</div>
                        <div class="description font-19 font-weight-500 text-black pt-12">{{ order.remark || '無' }}</div>
                    </div>
                </div>
                <!--   OrderNote -->
                <div class="font-15 text-dark-warm-grey px-3">* 接單後變更或取消訂單請直接聯繫餐廳。</div>
                <div class="font-15 text-dark-warm-grey px-3">* 如有其他疑問，請聯繫店家確認。</div>
                <div>
                    <a class="font-15 font-weight-500 pr-3" style="color: #4089e9; padding-left: 27px" :href="'tel:' + order.shop.phone" target="_blank"> 店家電話：{{ order.shop.phone }} </a>
                </div>
                <div>
                    <a class="font-15 font-weight-500 pr-3" style="color: #4089e9; padding-left: 27px" :href="'http://maps.google.com/?q=' + order.shop.address" target="_blank"> 店家地址：香港{{ order.shop.address }} </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '@/firebase'
import moment from 'moment'

export default {
    data() {
        return {
            order: null,
        }
    },

    async created() {
        await this.$bind('order', db.collection('orders').doc(this.$route.params.id))
        if (!this.order) return this.$router.push('/home')
    },

    computed: {
        computedStatus() {
            let obj = {
                text: '',
                timeText: '',
                class: '',
            }
            // 訂單狀態(1:新單 2:訂單完成 3:店家取消 4:待取餐 5:製作中 6:使用者取消訂單)
            switch (this.order.status) {
                case 'PENDING_PAYMENT':
                    obj.text = '等待客戶付款'
                    obj.class = 'text-black'
                    break
                case 'PENDING':
                    obj.text = '等待店家接單'
                    obj.class = 'text-black'
                    break
                case 'RECEIVED':
                    obj.text = '店家已接單'
                    obj.class = 'text-blue mb-2'
                    obj.timeText = '接單時間：' + moment(Number(this.order.receivedAt), 'X').format('YYYY/MM/DD HH:mm')
                    break
                case 'PACKED':
                    obj.text = this.order.method == 'DELIVERY' ? '餐點已完成，外送員將為您外送' : '可前往取餐'
                    obj.class = 'text-green mb-2'
                    obj.timeText = '餐點完成時間：' + moment(Number(this.order.packedAt), 'X').format('YYYY/MM/DD HH:mm')
                    break
                case 'COMPLETED':
                    obj.text = '已完成取餐'
                    obj.class = 'text-green'
                    obj.timeText = '訂單完成時間：' + moment(Number(this.order.completedAt), 'X').format('YYYY/MM/DD HH:mm')
                    break
                case 'VOID':
                    obj.text = '訂單已取消'
                    obj.class = 'text-red'
                    obj.timeText = ''
                    break
            }
            return obj
        },

        // 計算送餐時間句子
        orderReservationAt() {
            if (this.order.type == 'ASAP') return '越快越好'
            if (this.order.source == 'POS') return moment.unix(Number(this.order.completedAt)).format('MM/DD HH:mm')
            return moment.unix(Number(this.order.reservationAt)).format('MM/DD HH:mm')
        },
    },
}
</script>
